// Import our CSS
import styles from "../css/app.pcss";
import "swiper/swiper-bundle.css";

// App main
const main = async () => {
  await import("lazysizes/plugins/bgset/ls.bgset");
};

// Execute async function
main().then(() => {
  // Helpers
  var parseHTML = function (str) {
    const tmp = document.implementation.createHTMLDocument("tmp");
    tmp.body.innerHTML = str;
    return tmp.body.children;
  };

  var _si = function (selector, context = document, returnNull = false) {
    const tmp = context.querySelector(selector);
    return tmp ? tmp : returnNull ? null : _c("div");
  };
  var _s = function (selector, context = document) {
    return Array.apply(null, context.querySelectorAll(selector));
  };

  var _c = function (elm) {
    return document.createElement(elm);
  };

  var _ael = function (sel, ev, callback) {
    document.addEventListener(ev, (e) => {
      if (e.target === document) {
        return;
      }
      const that = e.target.closest(sel);
      if (that) {
        callback(that, e);
      }
    });
  };
  // END HELPERS
  const html = _si("html");
  const body = document.body;
  let navOpen = false;
  let langOpen = false;
  window.addEventListener("load", function () {
    body.style.opacity = "1";
    body.classList.add("loaded");
  });
  // js--slideshow-module
  const slideshow = new Swiper(".js--slideshow-module", {
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 100,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  const bigSlideshow = new Swiper(".js--slideshow-module-big", {
    slidesPerView: 1,
    speed: 400,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  const roomSlider = new Swiper(".js--room-slider", {
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 100,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    loop:true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  _ael(".js--open-room-slider", "click", (elm, e) => {
    e.preventDefault();
    let sliderWrapper = elm.closest("li");
    console.log(sliderWrapper.childNodes)
    // Index 5 er popupen .js--room-wrapper
    sliderWrapper.childNodes[7].classList.remove("hidden");
  });

  _ael(".js--close-room-slider", "click", (elm, e) => {
    e.preventDefault();
    let sliderWrapper = elm.closest(".js--room-wrapper");
    sliderWrapper.classList.add("hidden");
  });
  _ael("#js--toggle-menu", "click", (elm, e) => {
    e.preventDefault();
    if (!navOpen) {
      navOpen = true;
      body.classList.add("nav-open");
    } else {
      navOpen = false;
      body.classList.remove("nav-open");
    }
  });
  _ael("#js--open-lang-switch", "click", (elm, e) => {
    e.preventDefault();
    if (!langOpen) {
      langOpen = true;
      body.classList.add("lang-switch-open");
    } else {
      langOpen = false;
      body.classList.remove("lang-switch-open");
    }
  });
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
